import { RequestType } from './../models/module';
import { Pipe, PipeTransform } from '@angular/core';
import { Module } from '../models/module';
import { ModuleBase } from './module-base';

@Pipe({
  name: 'moduleTranslationIdentifier',
  standalone: true,
})
export class ModuleTranslationIdentifierPipe
  extends ModuleBase
  implements PipeTransform
{
  transform(module: Module, requestType: RequestType): string {
    let translationIdentifier = '';
    switch (module) {
      case 'ELECTRICITY': {
        translationIdentifier = `${'COMMON.MODULE.ELECTRICITY'}`;
        break;
      }
      case 'GAS': {
        translationIdentifier = `${'COMMON.MODULE.GAS'}`;
        break;
      }
      case 'WATER': {
        translationIdentifier = `${'COMMON.MODULE.WATER'}`;
        break;
      }
      case 'HEAT': {
        translationIdentifier = `${'COMMON.MODULE.HEAT'}`;
        break;
      }
      default: {
        this.throwUnsupportedError(module);
      }
    }
    return this.isRequestWithTypeCommissioning(requestType)
      ? `${translationIdentifier}_${requestType}`
      : translationIdentifier;
  }
}
