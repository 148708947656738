import { TranslateModule } from '@ngx-translate/core';
import { Component, computed, input, InputSignal } from '@angular/core';
import { Status } from './status';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { StatusMetaProvider } from '@app/shared/util/status-meta-provider';
import { Size } from './size';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'dso-status-indicator',
  imports: [TranslateModule, IconButtonComponent, CommonModule, MatIconModule],
  standalone: true,
  templateUrl: './status-indicator.component.html',
  styleUrl: './status-indicator.component.scss',
})
export class StatusIndicatorComponent {
  public readonly status: InputSignal<Status> = input.required<Status>();
  public readonly size: InputSignal<Size> = input<Size>('small');
  public readonly total: InputSignal<number | undefined> = input<
    number | undefined
  >();

  public readonly statusMeta = computed(() =>
    StatusMetaProvider.provide(this.status())
  );
}
