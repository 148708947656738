import { Module, RequestType } from '../models/module';

export abstract class ModuleBase {
  public isRequestWithTypeCommissioning(requestType?: RequestType): boolean {
    return requestType ? requestType === 'COMMISSIONING' : false;
  }

  public throwUnsupportedError(module: Module): void {
    throw new Error(`Not supported Module: ${module}`);
  }
}
