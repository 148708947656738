import { Pipe, PipeTransform } from '@angular/core';
import { Module, RequestType } from '../models/module';
import { ModuleBase } from './module-base';
import { CUSTOM_APP_ICON } from '@app/core/services';

@Pipe({
  name: 'moduleIconSelector',
  standalone: true,
})
export class ModuleIconSelectorPipe
  extends ModuleBase
  implements PipeTransform
{
  transform(module: Module, requestType?: RequestType): CUSTOM_APP_ICON {
    let icon: CUSTOM_APP_ICON;
    switch (module) {
      case 'ELECTRICITY': {
        if (this.isRequestWithTypeCommissioning(requestType)) {
          icon = CUSTOM_APP_ICON.MODULE_ELECTRICITY_COMMISSIONING;
          break;
        }
        icon = CUSTOM_APP_ICON.MODULE_ELECTRICITY;
        break;
      }
      case 'GAS': {
        icon = CUSTOM_APP_ICON.MODULE_GAS;
        break;
      }
      case 'WATER': {
        icon = CUSTOM_APP_ICON.MODULE_WATER;
        break;
      }
      case 'HEAT': {
        icon = CUSTOM_APP_ICON.MODULE_HEAT;
        break;
      }
      default: {
        this.throwUnsupportedError(module);
      }
    }
    return icon!;
  }
}
