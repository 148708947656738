import {
  ConnectionRequestDto,
  ContactDetailsDto,
  CustomerConfigurationDto,
  LocationDto,
  ModuleSettingDto,
} from 'src/api/dso-portal/generated/models';
import { Status } from '../ui/status-indicator/status';

const flowTypeMapping = new Map<
  ConnectionRequestDto['module'],
  ConnectionRequestDto['flowType'][]
>([
  [
    'ELECTRICITY',
    [
      'GRID_CONNECTION',
      'PV',
      'BALCONY_PV',
      'CHARGING_DEVICE',
      'HEAT_PUMP',
      'STORAGE',
      'MAIN_POWER_SUPPLY',
      'CUSTOMER_FACILITY',
      'OTHER',
    ],
  ],
  ['GAS', ['GRID_CONNECTION']],
  ['WATER', ['GRID_CONNECTION', 'SEWAGE', 'CONSTRUCTION_WATER']],
  ['HEAT', ['GRID_CONNECTION']],
]);

export interface RequestTableData {
  id?: string;
  module: ConnectionRequestDto['module'];
  requestType: ConnectionRequestDto['requestType'];
  requestId: string;
  flowType: ConnectionRequestDto['flowType'];
  location: LocationDto;
  requesterContact: ContactDetailsDto;
  createdAt: string;
  status: Status;
}

export class RequestsMapper {
  static mapModules(
    configuration: CustomerConfigurationDto
  ): ModuleSettingDto['module'][] {
    const registrationModules = configuration.moduleSettings.map(
      moduleSettings => moduleSettings.module
    );
    const commissioningModules = configuration.commissioningModules;
    return [...new Set(registrationModules.concat(commissioningModules))];
  }

  static mapFlowTypes(
    selectedModules: ConnectionRequestDto['module'][]
  ): ConnectionRequestDto['flowType'][] {
    let availableFlowTypes: ConnectionRequestDto['flowType'][] = [];
    selectedModules.forEach(module => {
      availableFlowTypes = availableFlowTypes.concat(
        flowTypeMapping.get(module)!
      );
    });
    return [...new Set(availableFlowTypes)];
  }

  static mapRequestList(
    requestList: ConnectionRequestDto[]
  ): RequestTableData[] {
    return requestList.map(request => ({
      id: request.id,
      module: request.module,
      requestId: request.requestId,
      requestType: request.requestType,
      flowType: request.flowType,
      location: request.location,
      requesterContact: request.requesterContact,
      createdAt: request.createdAt,
      status: request.currentStatus.status,
    }));
  }
}
